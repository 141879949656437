/**
 * created 30.11.2023
 */
import React, { ReactElement, useEffect, useMemo } from 'react';
import { StatusesType } from '../../interfaces-constants/common.interfaces';
import { usePageStyles } from '../../styles/styles.page';
import { withStyles } from '@mui/styles';
import { KIcon } from '../KIcon';
import { IconNameType } from '../../asset/svg';
import { Typography } from '@mui/material';
import { ViewWrapper } from '../ViewWrapper/ViewWrapper';
import { useMainProvider } from '../../context/MainProvider/MainProvider';
import { MyTheme } from '../../styles/my.theme';

interface ViewStatusComponentInterface {
  statusName: Partial<StatusesType>;
  title: string;
  subtitle?: string | ReactElement;
  extra?: ReactElement;
  classes?: any;
  hideButtons?: boolean;
}

function ViewStatusComponent(props: ViewStatusComponentInterface) {
  const {
    actions: { HideAllButtons },
    state: { contact_us_text },
  } = useMainProvider();
  const {
    statusName,
    title,
    subtitle = '',
    extra,
    classes = '',
    hideButtons = false,
  } = props;

  const classesPage = usePageStyles();

  useEffect(() => {
    if (hideButtons) {
      HideAllButtons();
    }
  }, [hideButtons]);

  const iconName = useMemo<IconNameType>(() => {
    switch (statusName) {
      case 'success':
        return 'checkCircle';
      case 'unsuccessful':
        return 'infoCircle';
      case 'under_review':
        return 'clockStopwatch';
      default:
        return 'checkCircle';
    }
  }, [statusName]);

  return (
    <>
      <div className={classesPage.container}>
        <div className={classes.iconContainer}>
          <KIcon icon={iconName} plenty />
        </div>
        <Typography variant={'subtitle1'}>{title}</Typography>
        <ViewWrapper enable={typeof subtitle === 'string'}>
          <Typography variant={'h2'} className={classes.correction}>
            {subtitle}
          </Typography>
        </ViewWrapper>
        <ViewWrapper disable={typeof subtitle === 'string'}>
          {subtitle}
        </ViewWrapper>
        {extra || (
          <Typography variant={'h4'} color={MyTheme.palette.primary.light}>
            {contact_us_text}
          </Typography>
        )}
      </div>
    </>
  );
}

export const ViewStatus = withStyles({
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  correction: {
    fontWeight: '400 !important',
  },
})(ViewStatusComponent);
