import {createTheme} from "@mui/material";
import {colors} from "../interfaces-constants/colors";

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
  interface Palette {
    buttonPalette: {
      containedBackground: string;
      containedColor: string;
      outlinedBackground: string;
      outlinedColor: string;
      outlinedBorder: string;
      brightBackground: string;
      darkBackground: string;
    };
    spaces: {
      main: number;
      middle: number;
      min: number;
      mainWidth: number;
      workspaceWidth: number;
      infoBlocksWidth: number;
    };
    bordersPalette: {
      dark: string;
      peach: string;
      midLight: string;
      light: string;
      error: string;
    }
  }

  interface PaletteOptions {
    buttonPalette?: {
      containedBackground: string;
      containedColor: string;
      outlinedBackground: string;
      outlinedColor: string;
      outlinedBorder: string;
      brightBackground: string;
      darkBackground: string;
    };
    spaces?: {
      main: number;
      middle: number;
      min: number;
      mainWidth: number;
      workspaceWidth: number;
      infoBlocksWidth: number;
    };
    bordersPalette?: {
      dark: string;
      peach: string;
      midLight: string;
      light: string;
      error: string;
    }
  }

  interface TypeBackground {
    lighter?: string;
    extraLight?: string;
  }

  interface TypographyOptions {
    myButton?: TypographyOptions
  }
}

export const MyTheme = createTheme({
  palette: {
    primary: {
      main: '#07F285',
      light: '#E9EEF7',
    },
    bordersPalette: {
      dark: colors.darkBorder,
      peach: colors.peachBorder,
      midLight: 'rgba(102, 112, 128, 0.50)',
      light: colors.greySecondary,
      error: colors.redPrimary,
    },
    spaces: {
      main: 54,
      middle: 32,
      min: 16,
      mainWidth: 952,
      workspaceWidth: 560,
      infoBlocksWidth: 872,
    },
    secondary: {
      main: '#667080',
      light: '#667080',
    },
    background: {
      default: colors.defaultBackground,
      lighter: colors.darkBackground,
    },
    buttonPalette: {
      containedBackground: colors.containedBackground,
      containedColor: colors.containedColor,
      outlinedBackground: colors.outlinedBackground,
      outlinedColor: colors.primary,
      outlinedBorder: colors.greySecondary,
      brightBackground: colors.brightBackground,
      darkBackground: colors.darkBackground,
    },
    text: {
      primary: colors.primary,
      secondary: colors.secondary,
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: 56,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '64px',
      color: colors.primary,
    },
    h2: {
      fontSize: 40,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '48px',
      color: colors.primary,
    },
    h3: {
      fontSize: 36,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '36px',
      color: colors.primary,
    },
    h4: {
      fontSize: 32,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '36px',
      color: colors.secondary,
    },
    subtitle1: {
      fontSize: 80,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      color: colors.primary,
    }
  },
});