import React from 'react';
import KeyboardKey from './KeyboardKey';
import styles from './keyboard.module.css';
import {
  phoneInitialValue,
  phoneValueLength,
} from '../../interfaces-constants/constant';

export type IconType = 'small' | 'big';

type KeyboardProps = {
  keysArray?: any;
  iconsType?: IconType;
  target?: any;
  targetValue?: any;
  maxLength?: number;
  newTargetValue?: any;
  availableArray?: boolean;
};

const Keyboard = ({
  keysArray,
  target,
  targetValue,
  maxLength = phoneValueLength,
  newTargetValue,
  availableArray = false,
  iconsType = 'big',
}: KeyboardProps) => {
  const sendNewTargetValue = (dataFromKey: string) => {
    newTargetValue(setNewValue(dataFromKey, targetValue, maxLength));

    function setNewValue(
      key: string,
      currentValue: any,
      maxLength = phoneValueLength,
    ) {
      if (
        key !== 'Clear' &&
        key !== 'Delete' &&
        key !== 'Uppercase' &&
        key !== 'Lowercase'
      ) {
        if (maxLength) {
          if (currentValue && currentValue.length === maxLength) {
            return currentValue;
          } else {
            return currentValue ? currentValue + '' + key : key;
          }
        } else {
          return currentValue ? currentValue + '' + key : key;
        }
      } else if (key === 'Clear') {
        return false;
      } else if (key === 'Delete') {
        if (target === 'phone') {
          if (currentValue && currentValue.length > phoneInitialValue.length) {
            return currentValue.substring(0, currentValue.length - 1);
          } else {
            return false;
          }
        }
        if (target === 'amount') {
          if (currentValue.substring(currentValue.length - 1) === '.') {
            return currentValue.substring(0, currentValue.length - 2);
          }
        }
        if (currentValue && currentValue.length > 0) {
          return currentValue.substring(0, currentValue.length - 1);
        } else {
          return '';
        }
      } else if (key === 'Uppercase') {
        return 'keyboardUppercase';
      } else if (key === 'Lowercase') {
        return 'keyboardLowercase';
      }
    }
  };

  return (
    <div className={`${styles.keyboard} float-container`}>
      {keysArray.map((row: any, i: any) => (
        <div
          key={'keyboard-row-' + i}
          className={`${styles.keyboardRow} float-container`}
        >
          {row.map((key: any, i: any) => (
            <KeyboardKey
              iconsType={iconsType}
              target={target}
              availableArray={availableArray}
              sendKeyValue={sendNewTargetValue}
              key={key + ' ' + i}
              value={key}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
