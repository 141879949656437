import { Theme } from '@mui/material';
import PhoneInput from 'react-phone-number-input/input';
import style from './PhoneInput.module.css';
import { KIcon } from '../KIcon';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';

type PhoneInputProps = {
  value: any;
  setValue: any;
  isValidPhone: boolean;
};

const PhoneInputComponent = ({
  value,
  setValue,
  isValidPhone,
}: PhoneInputProps) => {
  const clearInput = useCallback(() => setValue('+'), []);

  const classes = useStyles({ isValidPhone });

  return (
    <div className={classes.searchBox}>
      <PhoneInput
        className={style.phoneInput}
        value={value}
        onChange={setValue}
      />
      <KIcon
        icon={'closeIcon'}
        width={22}
        height={22}
        spaceRight={40}
        onPress={clearInput}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    maxWidth: '760px',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    outline: 'none',
    border: '4px solid',
    borderColor: (props: any) =>
      props.isValidPhone
        ? theme.palette.bordersPalette.dark
        : theme.palette.bordersPalette.error,
    borderRadius: '24px',
    fontSize: '32px',
    margin: '0 auto',
    color: '#ffffff',
    '& > img:nth-of-type(1)': {
      marginRight: '45px',
      cursor: 'pointer',
    },
  },
}));
export default PhoneInputComponent;
