
const m_path = {
  home: () => '/',
  authCode: () => '/auth_code',
  cashAmount: () => '/cash_amount',
  prepareId: () => '/prepare_id',
  scanConfirm: (type = ':type') => `/scan_confirm/${type}`,
  scanStatus: (status = ':status') => `/scan_status/${status}`,
  enterDetails: () => '/enter_details',
  checkScan: () => '/check_scan',
  checkDetails: () => '/check_details',
  checkUnderReview: (status = ':status') => `/check_under_review/${status}`,
  availableChecks: () => '/available_checks',
};

export const path: Record<keyof typeof m_path, (param?: string) => string> = m_path;

export const keyboards = {
  keyboardLowercase: [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'Delete'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '-', '_'],
    ['Uppercase', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@', '+'],
  ],
  keyboardUppercase: [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'Delete'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '-', '_'],
    ['Lowercase', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', '@', '+'],
  ],
  numpad: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9', '0'],
  ],
  numpadWithControls: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['Clear', '0', 'Delete'],
  ],
  numpadWithAmountControls: [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['.', '0', 'Delete'],
  ],
};

export const baseURI = process.env.REACT_APP_BASE_URI;

export const phoneValueLength = 13;
export const authCodeLength = 5;
export const cashAmountLength = 10;
export const phoneInitialValue = '+';


