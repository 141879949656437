/**
 * created 04.12.2023
 */
import React, { useCallback, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { usePageStyles } from '../styles/styles.page';
import { makeStyles } from '@mui/styles';
import { path } from '../interfaces-constants/constant';
import { checkDecide } from '../api/general.api';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';

function CheckDetailsComponent() {
  const {
    actions: {
      SetBottomButtons,
      Navigate,
      SetMainState,
      SetOverlay,
      CloseSession,
    },
    state: { checkDetails, error },
  } = useMainProvider();

  useEffect(() => {
    SetMainState({ error: '' });
  }, []);

  const onNext = useCallback(() => {
    doDecideRequest().then();
  }, []);

  async function doDecideRequest() {
    try {
      SetOverlay({
        isOpen: true,
        title: t('common.under_review'),
        subTitle: t('common.pleaseStandby'),
      });
      SetMainState({ error: '' });
      const response = await checkDecide();
      if (response.data.is_approved) {
        Navigate(path.checkUnderReview('success'));
      } else {
        Navigate(path.checkUnderReview('unsuccessful'));
      }
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        first: { enable: false },
        second: { enable: false },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        third: { enable: true, onAction: onNext, type: 'bright' },
      });
    }
  }, [onNext, error]);

  const classes = usePageStyles();
  const localClasses = useLocalStyles();
  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <Typography variant={'h1'}>{t('reviewPage.title')}</Typography>
            <Grid container className={classes.optionsBlock}>
              <Grid container className={localClasses.row}>
                <Grid xs={6} item>
                  <Typography variant={'h2'} className={classes.details}>
                    {t('reviewPage.checkType')}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant={'h2'} align={'right'}>
                    {checkDetails.check_type}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={localClasses.row}>
                <Grid xs={6} item>
                  <Typography variant={'h2'} className={classes.details}>
                    {t('reviewPage.checkAmount')}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant={'h2'} align={'right'}>
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: checkDetails.currency,
                    }).format(checkDetails.cash_amount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={localClasses.row}>
                <Grid xs={6} item>
                  <Typography variant={'h2'} className={classes.details}>
                    {t('reviewPage.freeAmount')}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant={'h2'} align={'right'}>
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: checkDetails.currency,
                    }).format(checkDetails.fee_amount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={6} item>
                  <Typography variant={'h2'} className={classes.details}>
                    {t('reviewPage.checkDate')}
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <Typography variant={'h2'} align={'right'}>
                    {checkDetails.check_date}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const CheckDetails = CheckDetailsComponent;

const useLocalStyles = makeStyles(() => ({
  row: {
    marginBottom: 32,
  },
}));
