/**
 * created 24.11.2023
 */
import React, { Context, useContext, useReducer } from 'react';
import { ActionParamsType } from '../types.context';
import {
  allowNextValue,
  createContextAction,
  GetStorageData,
  RemoveStorageValues,
  SetStorageData,
} from '../../helpers/main.helper';
import { IconNameType } from '../../asset/svg';
import {
  ButtonActionNameType,
  ButtonTypes,
} from '../../interfaces-constants/common.interfaces';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  closeSession,
  LocationDetailsType,
  OrganizationDetailsType,
  sessionInit,
} from '../../api/general.api';
import { CheckScanResponse } from '../../api/device.api';
import { path } from '../../interfaces-constants/constant';

enum MainEnums {
  SET_MAIN_STATE = 'SET_MAIN_STATE',
  SET_PHONE_NUMBER = 'SET_PHONE_NUMBER',
  SET_BOTTOM_BUTTONS = 'SET_BOTTOM_BUTTONS',
  SET_AUTH_CODE = 'SET_AUTH_CODE',
  SET_CASH_AMOUNT = 'SET_CASH_AMOUNT',
  SET_OVERLAY = 'SET_OVERLAY',
  SET_BIG_KEYBOARD = 'SET_BIG_KEYBOARD',
  STORE_LOCAL_IMAGES = 'STORE_LOCAL_IMAGES',
  CLEAR_LOCAL_IMAGES = 'CLEAR_LOCAL_IMAGES',
  CLEAR_STATE = 'CLEAR_STATE',
}

type ButtonSettingType = {
  enable: boolean;
  text: string;
  type: ButtonTypes;
  name: ButtonActionNameType;
  leftIcon: IconNameType;
  onAction: (name: ButtonActionNameType) => void;
};

type BottomButtonsType<T = ButtonSettingType> = {
  first: T;
  second: T;
  third: T;
};

type OverlayType = {
  isOpen: boolean;
  title: string;
  subTitle: string;
};

type BigKeyboardType = {
  isOpen: boolean;
  tempValue: string;
  currentField: string;
};
type LocalImagesType = { local_front: string; local_back: string };

interface MainProviderStateInterface {
  error: string;
  request: boolean;
  token: string;
  phoneNumber: string;
  authCode: string;
  cashAmount: string;
  isValidPhone: boolean;
  bigKeyboard: BigKeyboardType;
  validateAllow: boolean;
  overlay: OverlayType;
  bottomButtons: BottomButtonsType;
  checkDetails: CheckScanResponse;
  scannedImages: LocalImagesType;
  location_details: LocationDetailsType;
  organization_details: OrganizationDetailsType;
  contact_us_text: string;
}

interface MainProviderActionsInterface {
  SetMainState: (newState: Partial<MainProviderStateInterface>) => void;
  InitSession: (token: string) => Promise<any>;
  CloseSession: () => Promise<void>;
  SetPhoneNumber: (phone: string) => void;
  SetCashAmount: (amount: string) => void;
  SetAuthCode: (authCode: string) => void;
  SetBottomButtons: (
    newState: Partial<BottomButtonsType<Partial<ButtonSettingType>>>,
  ) => void;
  HideAllButtons: () => void;
  Navigate: (path: string, params?: { justNavigate: boolean }) => void;
  SetOverlay: (overlayState: Partial<OverlayType>) => void;
  SetBigKeyboard: (newState: Partial<BigKeyboardType>) => void;
  StoreScannedImages: (payload: LocalImagesType) => void;
  ClearLocalImages: () => void;
  ClearState: () => void;
}

type MainContextType = {
  state: MainProviderStateInterface;
  actions: MainProviderActionsInterface;
};

export const MainProviderContext: Context<MainContextType> =
  React.createContext<MainContextType>({
    state: init(),
    actions: {},
  } as MainContextType);

export const useMainProvider = () => useContext(MainProviderContext);

function initOverlay(): OverlayType {
  return {
    isOpen: false,
    title: 'Scanning ...',
    subTitle: 'Please standby',
  };
}

function initBigKeyboard(): BigKeyboardType {
  return {
    isOpen: false,
    currentField: '',
    tempValue: '',
  };
}

function initButtons(): BottomButtonsType {
  return {
    first: {
      enable: false,
      type: 'outlined',
      name: 'back',
      leftIcon: 'arrowLeft',
      text: t('footer.back'),
      onAction: name => console.info(name),
    },
    second: {
      enable: false,
      type: 'dark',
      name: 'resend',
      leftIcon: '',
      text: t('footer.resend'),
      onAction: name => console.info(name),
    },
    third: {
      enable: false,
      leftIcon: '',
      type: 'contained',
      name: 'next',
      text: t('footer.next'),
      onAction: name => console.info(name),
    },
  };
}

function init(): MainProviderStateInterface {
  return {
    token: '',
    error: '',
    request: false,
    phoneNumber: '+1',
    cashAmount: '0',
    authCode: '',
    bigKeyboard: initBigKeyboard(),
    isValidPhone: false,
    validateAllow: false,
    overlay: initOverlay(),
    bottomButtons: initButtons(),
    scannedImages: { local_back: '', local_front: '' },
    checkDetails: {
      fee_amount: 0,
      cash_amount: 0,
      check_date: '',
      check_type: '',
      currency: 'USD',
    },
    location_details: {
      address: '',
      country: 'USA',
      currency: 'USD',
      kiosk_model: '',
      kiosk_short_id: -1,
      name: '',
      timezone: 'US/Pacific',
    },
    organization_details: {
      contact_email: '',
      contact_phone_number: '',
      name: '',
    },
    contact_us_text: '',
  };
}

function reducer(state: MainProviderStateInterface, action: ActionParamsType) {
  const { type, payload } = action;
  switch (type) {
    case MainEnums.SET_MAIN_STATE: {
      return {
        ...state,
        ...(payload as Partial<MainProviderStateInterface>),
      };
    }
    case MainEnums.SET_PHONE_NUMBER: {
      const phoneNumber = (payload as string) || '';
      return {
        ...state,
        phoneNumber: phoneNumber === '' ? '+' : phoneNumber,
        isValidPhone: phoneNumber.length === 12 || phoneNumber.length === 13,
      };
    }
    case MainEnums.SET_CASH_AMOUNT: {
      const cashAmount = (payload as string) || '';

      return {
        ...state,
        cashAmount: cashAmount,
      };
    }
    case MainEnums.SET_BIG_KEYBOARD: {
      const { currentField } = payload as Partial<BigKeyboardType>;
      const newState = {
        ...state.bigKeyboard,
        tempValue:
          !!currentField && currentField !== state.bigKeyboard.currentField
            ? ''
            : state.bigKeyboard.tempValue,
        ...(payload as Partial<BigKeyboardType>),
      };
      if (newState.tempValue !== undefined && newState.tempValue !== '') {
        if (!allowNextValue(newState.tempValue, newState.currentField)) {
          newState.tempValue = state.bigKeyboard.tempValue;
        }
      }

      return {
        ...state,
        bigKeyboard: newState,
      };
    }
    case MainEnums.SET_AUTH_CODE: {
      const authCode = (payload as string) || '';
      return {
        ...state,
        authCode: authCode,
      };
    }
    case MainEnums.SET_OVERLAY: {
      const newState = payload as Partial<OverlayType>;

      return {
        ...state,
        overlay: {
          ...state.overlay,
          ...newState,
        },
      };
    }
    case MainEnums.STORE_LOCAL_IMAGES: {
      return {
        ...state,
        scannedImages: payload as LocalImagesType,
      };
    }
    case MainEnums.CLEAR_LOCAL_IMAGES: {
      return {
        ...state,
        scannedImages: { local_front: '', local_back: '' },
      };
    }
    case MainEnums.SET_BOTTOM_BUTTONS: {
      const {
        first = {},
        second = {},
        third = {},
      } = payload as Partial<BottomButtonsType<Partial<ButtonSettingType>>>;

      return {
        ...state,
        bottomButtons: {
          ...state.bottomButtons,
          first: {
            ...state.bottomButtons.first,
            ...first,
          },
          second: {
            ...state.bottomButtons.second,
            ...second,
          },
          third: {
            ...state.bottomButtons.third,
            ...third,
          },
        },
      };
    }
    case MainEnums.CLEAR_STATE: {
      return {
        ...init(),
      };
    }
    default:
      return state;
  }
}

function MainProviderComponent(props: any) {
  const [state, dispatch] = useReducer(reducer, null, init);
  const navigate = useNavigate();

  const actions: MainProviderActionsInterface = {
    SetMainState: createContextAction<Partial<MainProviderStateInterface>>(
      MainEnums.SET_MAIN_STATE,
      dispatch,
    ),
    SetPhoneNumber: createContextAction<string>(
      MainEnums.SET_PHONE_NUMBER,
      dispatch,
    ),
    StoreScannedImages: createContextAction<LocalImagesType>(
      MainEnums.STORE_LOCAL_IMAGES,
      dispatch,
    ),
    ClearLocalImages: createContextAction(
      MainEnums.CLEAR_LOCAL_IMAGES,
      dispatch,
    ),
    SetAuthCode: createContextAction<string>(MainEnums.SET_AUTH_CODE, dispatch),
    SetCashAmount: createContextAction<string>(
      MainEnums.SET_CASH_AMOUNT,
      dispatch,
    ),
    SetOverlay: createContextAction<Partial<OverlayType>>(
      MainEnums.SET_OVERLAY,
      dispatch,
    ),
    SetBigKeyboard: createContextAction<Partial<BigKeyboardType>>(
      MainEnums.SET_BIG_KEYBOARD,
      dispatch,
    ),
    SetBottomButtons: createContextAction<
      Partial<BottomButtonsType<Partial<ButtonSettingType>>>
    >(MainEnums.SET_BOTTOM_BUTTONS, dispatch),
    ClearState: createContextAction(MainEnums.CLEAR_STATE, dispatch),
    InitSession: async deviceToken => {
      try {
        actions.SetOverlay({ isOpen: true, title: t('common.processing') });
        if (!deviceToken) {
          const session_token = GetStorageData('session_token');
          actions.SetMainState({ token: session_token });
          return Promise.resolve(session_token);
        }
        const response = await sessionInit({ platform_session: deviceToken });
        if (!response.data.session_token) {
          throw new Error('session token is not set');
        }
        actions.SetMainState({
          token: response.data.session_token,
          organization_details: response.data.organization_details,
          location_details: response.data.location_details,
          contact_us_text: `Please contact our support: email ${response.data.organization_details.contact_email}, phone ${response.data.organization_details.contact_phone_number}`,
        });
        SetStorageData('session_token', response.data.session_token);
        return Promise.resolve(response.data.session_token);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      } finally {
        actions.SetOverlay({ isOpen: false });
      }
    },
    CloseSession: async () => {
      try {
        actions.SetOverlay({ isOpen: true, title: t('common.processing') });
        await closeSession();
        RemoveStorageValues([
          'customer_phone',
          'is_customer_verified',
          'session_token',
        ]);
        // actions.ClearState();
        actions.SetMainState({ token: '' });
        actions.Navigate(path.home(), { justNavigate: true });
      } catch (error) {
        actions.SetMainState({ error: error as string });
      }
    },
    HideAllButtons: () => {
      actions.SetBottomButtons(initButtons());
    },
    Navigate: (path: string, params?: { justNavigate: boolean }) => {
      if (!params?.justNavigate) {
        actions.HideAllButtons();
        actions.SetOverlay(initOverlay());
        actions.SetBigKeyboard(initBigKeyboard());
        actions.SetMainState({ validateAllow: false });
      }
      if (path === 'back') {
        navigate(-1);
      } else {
        navigate(path);
      }
    },
  };

  return (
    <MainProviderContext.Provider value={{ state, actions }}>
      {props.children}
    </MainProviderContext.Provider>
  );
}

export const MainProvider = MainProviderComponent;
