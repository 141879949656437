/**
 * created 05.12.2023
 */
import React, { useCallback, useEffect } from 'react';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { usePageStyles } from '../styles/styles.page';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { CheckItem } from '../components/CheckItem';
import { getAvailableChecks } from '../api/general.api';
import { GetErrorMessageFromResponse } from '../helpers/main.helper';
import {path} from "../interfaces-constants/constant";

function AvailableChecksComponent() {
  const classes = usePageStyles();
  const localClasses = useLocalClasses();
  const {
    actions: { Navigate, SetMainState, SetOverlay },
  } = useMainProvider();

  useEffect(() => {
    doRequest().then();
  }, []);

  async function doRequest() {
    try {
      SetOverlay({ isOpen: true });
      SetMainState({ error: '' });
      const response = await getAvailableChecks();
      if (response.code) {
        throw new Error(GetErrorMessageFromResponse(response));
      }
      if (response.data.length === 0) {
        Navigate(path.cashAmount());
      }
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  const onItemPress = useCallback((id: string) => {
    console.info('on item pressed', id);
  }, []);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.infoContainer}>
          <Typography variant={'h1'}>
            {t('availableChecksPage.title')}
          </Typography>
        </div>
        <Grid container direction={'row'} className={localClasses.list} gap={6}>
          <CheckItem
            type={'active'}
            amount={'$50'}
            date={'22/08/2023'}
            onPress={onItemPress}
          />
          <CheckItem type={'active'} amount={'$100'} date={'22/08/2023'} />
          <CheckItem type={'inactive'} amount={'$1000'} date={'22/08/2023'} />
          <CheckItem type={'inactive'} amount={'$300.99'} date={'22/08/2023'} />
        </Grid>
      </div>
    </>
  );
}

export const AvailableChecks = AvailableChecksComponent;

const useLocalClasses = makeStyles(() => ({
  list: {
    paddingLeft: 80,
    paddingRight: 80,
  },
}));
