/**
 * created 24.11.2023
 */
import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { ViewWrapper } from '../ViewWrapper/ViewWrapper';
import { KButton } from '../KButton/KButton';
import { useMainProvider } from '../../context/MainProvider/MainProvider';
import { KeyboardFull } from '../Keyboard/KeyboardFull';

function CartFooterComponent() {
  const {
    actions: { SetBigKeyboard },
    state,
  } = useMainProvider();
  const {
    bottomButtons,
    bigKeyboard: { isOpen, tempValue, currentField },
  } = state;
  const { first, second, third } = bottomButtons;

  const onSetValue = useCallback(
    (value: string) => {
      SetBigKeyboard({ tempValue: value });
    },
    [tempValue, currentField],
  );

  return (
    <>
      <Box>
        <ViewWrapper enable={isOpen}>
          <KeyboardFull value={tempValue} setValue={onSetValue} />
        </ViewWrapper>
        <Box sx={styles.totalBox}>
          <ViewWrapper enable={first.enable} dontRemove>
            <KButton
              type={first.type}
              text={first.text}
              onPress={first.onAction}
              name={first.name}
              leftIcon={first.leftIcon}
            />
          </ViewWrapper>
          <ViewWrapper enable={second.enable} dontRemove>
            <KButton
              type={second.type}
              text={second.text}
              onPress={second.onAction}
              name={second.name}
              leftIcon={second.leftIcon}
            />
          </ViewWrapper>
          <ViewWrapper enable={third.enable} dontRemove>
            <KButton
              type={third.type}
              text={third.text}
              onPress={third.onAction}
              name={third.name}
              leftIcon={third.leftIcon}
            />
          </ViewWrapper>
        </Box>
      </Box>
    </>
  );
}

export const CartFooter = CartFooterComponent;

const styles = {
  totalBox: {
    color: '#ffffff',
    fontSize: '40px',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#111111',
  },
};
