import { Theme, Typography } from '@mui/material';
import style from './CodeInput.module.css';
import { makeStyles } from '@mui/styles';
import { MyTheme } from '../../styles/my.theme';
import React from 'react';

type PhoneInputProps = {
  value: any;
  setValue: (value: string) => void;
  errorMsg?: string;
  isValid: boolean;
};

const CodeInputComponent = ({
  value,
  setValue,
  isValid,
  errorMsg,
}: PhoneInputProps) => {
  const classes = useStyles({ isValid, errorMsg });
  return (
    <div className={classes.container}>
      <div className={classes.searchBox}>
        <input
          className={style.phoneInput}
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </div>
      <div className={classes.errorMsg}>
        <Typography variant={'h4'} color={MyTheme.palette.error.main}>
          {errorMsg}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    maxWidth: '760px',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.05)',
    outline: 'none',
    border: '4px #667080 solid',
    borderColor: (props: any) =>
      props.isValid
        ? theme.palette.bordersPalette.light
        : theme.palette.bordersPalette.error,
    borderRadius: '24px',
    fontSize: '32px',
    margin: '0 auto',
    color: '#ffffff',
    '& > img:nth-of-type(1)': {
      marginRight: '45px',
      cursor: 'pointer',
    },
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '760px',
    gap: theme.palette.spaces.min,
  },
  errorMsg: {
    visibility: (props: any) => (props.errorMsg ? 'visible' : 'hidden'),
    height: 24,
    color: theme.palette.error.main,
    paddingTop: theme.palette.spaces.min,
  },
}));
export default CodeInputComponent;
