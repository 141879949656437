/**
 * created 24.11.2023
 */
import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { CartFooter } from './CartFooter';
import { LoaderOverlay } from './LoaderOverlay';
import { useMainProvider } from '../../context/MainProvider/MainProvider';
import { ViewWrapper } from '../ViewWrapper/ViewWrapper';
import { GetStorageData } from '../../helpers/main.helper'; // interface LayoutComponentInterface {
// interface LayoutComponentInterface {
// }

function LayoutComponent() {
  const {
    state: {
      overlay: { isOpen, title, subTitle },
      token,
    },
    actions: { SetMainState },
  } = useMainProvider();

  useEffect(() => {
    if (!token) {
      const session_token = GetStorageData('session_token');
      SetMainState({ token: session_token });
    }
  }, [token]);

  return (
    <Box sx={styles.container}>
      <LoaderOverlay title={title} isOpen={isOpen} subTitle={subTitle} />
      <ViewWrapper enable={!!token}>
        <Box sx={styles.box}>
          <Box sx={styles.box2}>
            <Header />
            <Outlet />
          </Box>
          <CartFooter />
        </Box>
      </ViewWrapper>
      <ViewWrapper enable={!token}>
        <Typography variant={'h2'} align={'center'}>
          There is no active session token
        </Typography>
      </ViewWrapper>
    </Box>
  );
}

export const Layout = LayoutComponent;

const styles = {
  container: {
    background: '#111111',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    margin: '0 60px',
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
    flex: '1 auto',
  },
  box2: {
    display: 'flex',
    flexDirection: 'column',
  },
};
