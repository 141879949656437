/**
 * created 24.11.2023
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { usePageStyles } from '../styles/styles.page';
import PhoneInput from '../components/PhoneInput/PhoneInput';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import KeyBoardPhone from '../components/Keyboard/KeyBoardPhone';
import { path } from '../interfaces-constants/constant';
import { t } from 'i18next';
import { customerAuthorize } from '../api/general.api';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { SetStorageData } from '../helpers/main.helper'; // interface HomeComponentInterface {

// interface HomeComponentInterface {
// }

function HomeComponent() {
  const {
    state: { phoneNumber, isValidPhone, validateAllow },
    actions: {
      SetPhoneNumber,
      SetBottomButtons,
      SetMainState,
      Navigate,
      SetOverlay,
      CloseSession,
    },
  } = useMainProvider();
  const [error, SetError] = useState<string | null>(null);
  const classes = usePageStyles();

  const isValid = useMemo(
    () => (validateAllow ? isValidPhone : true),
    [isValidPhone, validateAllow],
  );

  const onNext = useCallback(() => {
    SetMainState({ validateAllow: true });
    if (isValidPhone) {
      onAuthorize();
    }
    // Navigate(path.enterDetails());
  }, [isValidPhone, phoneNumber]);

  function onAuthorize() {
    SetOverlay({ isOpen: true, title: t('common.processing') });
    customerAuthorize({ phone_number: phoneNumber })
      .then(() => {
        SetStorageData('customer_phone', phoneNumber);
        Navigate(path.authCode());
      })
      .catch(error => {
        SetError(error as string);
      })
      .finally(() => {
        SetOverlay({ isOpen: false });
      });
  }

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        second: {
          enable: true,
          onAction: () => SetError(''),
          text: 'Try again',
        },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        third: {
          enable: true,
          onAction: onNext,
          type: isValidPhone ? 'bright' : 'contained',
        },
        second: { enable: false },
      });
    }
  }, [onNext, error, isValidPhone]);

  return (
    <div className={classes.container}>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error as string}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <Typography variant={'h2'}>{t('phonePage.title')}</Typography>
        <div className={classes.phoneContainer} style={{ marginTop: -53 }}>
          <PhoneInput
            value={phoneNumber}
            isValidPhone={isValid}
            setValue={SetPhoneNumber}
          />
          <Typography variant={'h4'}>{t('phonePage.description')}</Typography>
        </div>
        <div className={classes.phoneKeyboardContainer}>
          <KeyBoardPhone
            value={phoneNumber}
            setValue={SetPhoneNumber}
            length={false}
          />
        </div>
      </ViewWrapper>
    </div>
  );
}

export const Home = HomeComponent;
