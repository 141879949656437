export const colors = {
  containedColor: '#121421',
  outlinedBackground: '#1C1C1C',
  containedBackground: '#FFCD29',
  brightBackground: '#FFCD29',
  darkBackground: '#282828',
  defaultBackground: '#111111',
  infoDarkBackground: '#232323',

  primary: '#FFFFFF',
  primaryLight: '#DEE0FB',
  darkGrey: '#7D7E8B',
  secondary: '#A4A4A4',
  greySecondary: '#667080',

  redPrimary: '#F71E55',
  darkBorder: '#272727',
  peachBorder: '#FAB665',
};