/**
 * created 01.12.2023
 */
import React, { useCallback, useState } from 'react';
import style from './keyboard.module.css';
import Keyboard from './Keyboard';
import { keyboards } from '../../interfaces-constants/constant';

type KeyboardCaseType = 'keyboardLowercase' | 'keyboardUppercase';

interface KeyboardFullComponentInterface {
  value: any;
  setValue: any;
  autoLowCase?: boolean;
}

function KeyboardFullComponent(props: KeyboardFullComponentInterface) {
  const { value, setValue, autoLowCase = true } = props;
  const [keyboardCase, SetKeyboardCase] =
    useState<KeyboardCaseType>('keyboardLowercase');

  const _setValue = useCallback(
    (value: string) => {
        // console.info('_set value:', value);
      if (value === 'keyboardUppercase' || value === 'keyboardLowercase') {
        SetKeyboardCase(value);
      } else {
        if (typeof setValue === 'function') {
          setValue(value);
          if (keyboardCase === 'keyboardUppercase' && autoLowCase) {
            SetKeyboardCase('keyboardLowercase');
          }
        }
      }
    },
    [keyboardCase, autoLowCase, setValue],
  );

  return (
    <>
      <div className={style.keyboardContainer}>
        <div className={style.keyboardLetter}>
          <Keyboard
            iconsType={'small'}
            keysArray={keyboards[keyboardCase]}
            targetValue={value}
            newTargetValue={_setValue}
            maxLength={48}
          />
        </div>
        <div className={style.keyboardNumpad}>
          <Keyboard
            iconsType={'small'}
            keysArray={keyboards.numpad}
            targetValue={value}
            newTargetValue={_setValue}
            maxLength={48}
          />
        </div>
      </div>
    </>
  );
}

export const KeyboardFull = KeyboardFullComponent;
