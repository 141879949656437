/**
 * created 04.12.2023
 */
import React, { useCallback, useEffect, useState } from 'react';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { usePageStyles } from '../styles/styles.page';
import { t } from 'i18next';
import { Grid, Typography } from '@mui/material';
import { path } from '../interfaces-constants/constant';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { finishCheck } from '../api/general.api';

type StatusesType = 'standby' | 'success' | 'unsuccessful';

function UnderReviewComponent() {
  const pageClasses = usePageStyles();
  const localClasses = useLocalClasses();
  const params = useParams<{ status: StatusesType }>();
  const [status] = useState<StatusesType>(params.status || 'success');
  const {
    actions: {
      SetBottomButtons,
      Navigate,
      SetOverlay,
      SetMainState,
      CloseSession,
    },
    state: { checkDetails, contact_us_text },
  } = useMainProvider();

  const onNext = useCallback(() => {
    CloseSession().then();
  }, []);

  useEffect(() => {
    SetMainState({ error: '' });
    if (status === 'success') {
      doCheckFinish().then();
    }
  }, []);

  async function doCheckFinish() {
    try {
      SetOverlay({
        isOpen: true,
        title: t('common.under_review'),
        subTitle: t('common.pleaseStandby'),
      });
      SetMainState({ error: '' });
      await finishCheck();
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  useEffect(() => {
    if (status === 'standby') {
      SetBottomButtons({
        first: {
          enable: true,
          onAction: () => Navigate(path.checkDetails()),
        },
        second: { enable: false },
        third: { enable: false },
      });
    }
    if (status === 'success') {
      SetBottomButtons({
        // first: {
        //   enable: true,
        //   onAction: () => Navigate(path.checkDetails()),
        // },
        second: { enable: false },
        third: {
          enable: true,
          onAction: onNext,
          text: t('footer.exit'),
          type: 'bright',
        },
      });
    }
    if (status === 'unsuccessful') {
      SetBottomButtons({
        first: { enable: false },
        second: {
          enable: true,
          onAction: () => Navigate(path.checkScan()),
          text: t('footer.try'),
        },
        third: {
          enable: true,
          type: 'bright',
          text: t('footer.exit'),
          onAction: onNext,
        },
      });
    }
  }, [onNext, status]);

  return (
    <>
      <div className={pageClasses.container}>
        <ViewWrapper enable={status === 'standby'}>
          <ViewStatus
            statusName={'under_review'}
            title={t('underReviewPage.title')}
            extra={
              <Typography variant={'h4'}>
                {t('common.pleaseStandby')}
              </Typography>
            }
          />
        </ViewWrapper>
        <ViewWrapper enable={status === 'success'}>
          <ViewStatus
            statusName={'success'}
            title={t('underReviewPage.successTitle')}
            subtitle={
              <Grid container direction={'column'} gap={6}>
                <Typography variant={'h2'} className={localClasses.correction}>
                  {t('underReviewPage.successSubtitle')}
                </Typography>
                <Typography variant={'h2'} className={localClasses.correction}>
                  {t('underReviewPage.successSub2')}{' '}
                  <span className={localClasses.amount}>
                    {Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: checkDetails.currency,
                    }).format(
                      checkDetails.cash_amount - checkDetails.fee_amount,
                    )}
                  </span>
                </Typography>
              </Grid>
            }
            extra={<span />}
          />
        </ViewWrapper>
        <ViewWrapper enable={status === 'unsuccessful'}>
          <ViewStatus
            statusName={'unsuccessful'}
            title={t('scanStatusPage.uTitle')}
            subtitle={`${t('underReviewPage.unsuccessfulSubtitle')} ${contact_us_text}`}
            extra={<span />}
          />
        </ViewWrapper>
      </div>
    </>
  );
}

export const UnderReview = UnderReviewComponent;

const useLocalClasses = makeStyles(() => ({
  correction: {
    fontWeight: '400 !important',
  },
  amount: {
    fontWeight: 700,
  },
}));
