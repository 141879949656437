import { APIResponseInterface } from '../interfaces-constants/common.interfaces';

export const createContextAction =
  <T = object>(type: string, dispatch: any, logging = true) =>
  (state?: T) => {
    if (logging) {
      console.log(
        `%c hook action type: %c ${type}  %c payload: %c`,
        'color:darkgreen; font-weight: bold',
        'color:#7c72ff',
        'color:darkgreen; font-weight: bold',
        'color:#ffffff',
        state,
      );
    }
    return dispatch({ type, payload: state });
  };

type LocalStorageKeys =
  | 'session_token'
  | 'device_token'
  | 'customer_phone'
  | 'is_customer_verified';

export function SetStorageData(
  key: LocalStorageKeys,
  value: string | object | boolean,
) {
  const mod_value =
    typeof value === 'object'
      ? `object:${JSON.stringify(value)}`
      : typeof value === 'boolean'
        ? `bool:${value}`
        : value;
  localStorage.setItem(key, mod_value);
}

export function GetStorageData<T = string>(key: LocalStorageKeys): T {
  const value = localStorage.getItem(key) || '';
  const splitted = value.split(':');
  switch (splitted[0]) {
    case 'bool':
      // @ts-expect-error you should set T as boolean
      return splitted[1] === 'true';
    case 'object':
      return JSON.parse(splitted[1]);
    default:
      // @ts-expect-error by default type is string
      return value;
  }
}

export function RemoveStorageValues(keys: Array<LocalStorageKeys>) {
  keys.map(key => localStorage.removeItem(key));
}

export function GetErrorMessageFromResponse(
  response: APIResponseInterface<any>,
): string {
  return response?.data?.details
    ? response?.data?.details
    : response.message;
  // return `error #${response.code}: ${message}`;
}

export function allowNextValue(value: string, fieldName: string) {
  let testRes = false;
  switch (fieldName) {
    case 'zip':
      testRes = /^\d+$/g.test(value);
      break;
    default:
      testRes = /^[\w+._\-@]+$/g.test(value);
  }
  return testRes;
}
