/**
 * created 27.11.2023
 */
import React, { useCallback, useMemo } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { IconNameType } from '../../asset/svg';
import {
  ButtonActionNameType,
  ButtonTypes,
} from '../../interfaces-constants/common.interfaces';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import {KIcon} from "../KIcon";

interface KButtonComponentInterface {
  leftIcon?: IconNameType | '';
  text: string;
  type: ButtonTypes;
  name?: ButtonActionNameType;
  onPress?: (name: ButtonActionNameType) => void;
}

const buttonCommon: any = {
  minHeight: 80,
  minWidth: 140,
  fontSize: 28,
  textTransform: 'none',
  fontWeight: 700,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  lineHeight: '32px',
  borderRadius: 16,
  paddingLeft: 40,
  paddingRight: 40,
};

function KButtonComponent(props: KButtonComponentInterface) {
  const { type, text = '', leftIcon = '', onPress, name = 'back' } = props;

  const variant = useMemo(() => {
    switch (type) {
      case 'contained':
      case 'bright':
      case 'dark':
        return 'contained';
      default:
        return 'outlined';
    }
  }, [type]);

  const MyButton = useMemo(() => {
    switch (type) {
      case 'contained':
        return ContainedButton;
      case 'bright':
        return BrightButton;
      case 'dark':
        return DarkButton;
      case 'outlined':
        return OutlinedButton;
      default:
        return ContainedButton;
    }
  }, [type]);

  const _onPress = useCallback(() => {
    if (typeof onPress === 'function') {
      onPress(name);
    }
  }, [onPress, name]);

  return (
    <>
      <MyButton variant={variant} startIcon={<KIcon icon={leftIcon} plenty/>} onClick={_onPress}>
        {text}
      </MyButton>
    </>
  );
}

export const KButton = withStyles({
  root: {
    padding: '12px 32px',
  },
})(KButtonComponent);

const ContainedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.buttonPalette.containedColor,
  opacity: 0.6,
  backgroundColor: theme.palette.buttonPalette.containedBackground,
  '&:hover': {
    backgroundColor: theme.palette.buttonPalette.containedBackground,
    opacity: 1,
  },
  ...buttonCommon,
  fontSize: 36,
  fontWeight: 500,
  lineHeight: '56px',
  letterSpacing: '0.72px',
}));
const BrightButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.buttonPalette.containedColor,
  backgroundColor: theme.palette.buttonPalette.brightBackground,
  '&:hover': {
    backgroundColor: theme.palette.buttonPalette.brightBackground,
    opacity: 0.7,
  },
  ...buttonCommon,
  fontSize: 36,
  fontWeight: 500,
  lineHeight: '56px',
  letterSpacing: '0.72px',
}));
const DarkButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.buttonPalette.outlinedColor,
  backgroundColor: theme.palette.buttonPalette.darkBackground,
  '&:hover': {
    backgroundColor: theme.palette.buttonPalette.darkBackground,
    opacity: 0.7,
  },
  ...buttonCommon,
}));
const OutlinedButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.buttonPalette.outlinedColor,
  backgroundColor: theme.palette.buttonPalette.outlinedBackground,
  '&:hover': {
    backgroundColor: theme.palette.buttonPalette.outlinedBackground,
    borderColor: theme.palette.buttonPalette.outlinedBorder,
    opacity: 0.7,
    borderWidth: 4,
  },
  ...buttonCommon,
  borderStyle: 'solid',
  textTransform: 'none',
  borderWidth: 4,
  borderColor: theme.palette.buttonPalette.outlinedBorder,
}));
