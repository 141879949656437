/**
 * created 01.12.2023
 */
import React, { useCallback, useEffect, useMemo } from 'react';
import { usePageStyles } from '../styles/styles.page';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { t } from 'i18next';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { path } from '../interfaces-constants/constant';
import { useParams } from 'react-router-dom';
import { StatusesType } from '../interfaces-constants/common.interfaces';

function ScanStatusComponent() {
  const {
    actions: { SetBottomButtons, Navigate },
  } = useMainProvider();
  const params = useParams();
  const pageClasses = usePageStyles();

  const pageStatus = useMemo<StatusesType>(
    () => (params?.status as StatusesType) || 'success',
    [params],
  );

  const onNext = useCallback(() => {
    Navigate(
      pageStatus === 'success' ? path.cashAmount() : path.enterDetails(),
    );
  }, [pageStatus]);

  useEffect(() => {
    if (pageStatus === 'success') {
      SetBottomButtons({
        third: {
          enable: true,
          onAction: onNext,
          text: t('footer.continue'),
          type: 'bright',
        },
      });
    }
    if (pageStatus === 'unsuccessful') {
      SetBottomButtons({
        first: {
          enable: true,
          onAction: () => Navigate(path.prepareId()),
          text: t('footer.retryScan'),
        },
        third: {
          enable: true,
          onAction: onNext,
          text: t('footer.enterMan'),
          type: 'bright',
        },
      });
    }
  }, [onNext, pageStatus]);

  const { title, subtitle, status } = useMemo<{
    title: string;
    subtitle: string;
    status: StatusesType;
  }>(() => {
    switch (pageStatus) {
      case 'success':
        return {
          title: t('scanStatusPage.title'),
          subtitle: t('scanStatusPage.description'),
          status: pageStatus,
        };
      case 'unsuccessful':
        return {
          title: t('scanStatusPage.uTitle'),
          subtitle: t('scanStatusPage.uDescription'),
          status: pageStatus,
        };
      default:
        return {
          title: t('scanStatusPage.title'),
          subtitle: t('scanStatusPage.description'),
          status: pageStatus,
        };
    }
  }, [pageStatus]);

  return (
    <>
      <div className={pageClasses.container}>
        <ViewStatus
          statusName={status}
          title={title}
          subtitle={subtitle}
          extra={<span />}
        />
      </div>
    </>
  );
}

export const ScanStatus = ScanStatusComponent;
