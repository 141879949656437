import React from 'react';
import { Box } from '@mui/material';
import Keyboard from './Keyboard';
import {
  authCodeLength,
  keyboards,
  phoneValueLength,
} from '../../interfaces-constants/constant';
import style from './keyboard.module.css';

type KeyBoardPhoneProps = {
  value: any;
  setValue: any;
  length: boolean;
};

const KeyBoardPhone = ({ value, setValue, length }: KeyBoardPhoneProps) => {
  return (
    <Box className={style.keyboardPhone}>
      <Keyboard
        keysArray={keyboards.numpadWithControls}
        targetValue={value}
        newTargetValue={setValue}
        target={'phone'}
        maxLength={!length ? phoneValueLength : authCodeLength}
      />
    </Box>
  );
};

export default KeyBoardPhone;
