/**
 * created 04.12.2023
 */
import React, { useCallback, useEffect } from 'react';
import { Typography } from '@mui/material';
import { usePageStyles } from '../styles/styles.page';
import scanCheckImage from '../asset/images/scan_check_image.jpg';
import { path } from '../interfaces-constants/constant';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { t } from 'i18next';
import {
  disableScannerRequest,
  enableScannerRequest,
  localScanRequest,
} from '../api/device.api';
import { GetErrorMessageFromResponse } from '../helpers/main.helper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';

function CheckScanComponent() {
  const {
    actions: {
      SetBottomButtons,
      Navigate,
      SetMainState,
      SetOverlay,
      StoreScannedImages,
      ClearLocalImages,
      CloseSession,
    },
    state: { error },
  } = useMainProvider();
  const classes = usePageStyles();

  useEffect(() => {
    enableScannerRequest({ check_type: 'check' })
      .then(response => {
        if (response.code) {
          throw new Error(GetErrorMessageFromResponse(response));
        }
      })
      .catch(error => {
        SetMainState({ error: error as string });
      });
  }, []);

  const onNext = useCallback(() => {
    doCheckScan().then();
  }, []);

  async function doCheckScan() {
    try {
      SetOverlay({ isOpen: true });
      SetMainState({ error: '' });
      ClearLocalImages();
      const response = await localScanRequest();
      StoreScannedImages(response.data);
      Navigate(path.scanConfirm('check'));
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  useEffect(() => {
    if (error) {
      disableScannerRequest().then();
      SetBottomButtons({
        first: { enable: false },
        second: { enable: false },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        first: {
          enable: true,
          onAction: () => {
            disableScannerRequest().then();
            Navigate(path.cashAmount());
          },
        },
        third: {
          enable: true,
          onAction: onNext,
          text: "I'm ready",
          type: 'bright',
        },
      });
    }
  }, [onNext, error]);

  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <Typography variant={'h1'}>{t('scanCheckPage.title')}</Typography>
            <div className={classes.optionsBlock}>
              <Typography variant={'h2'} className={classes.options}>
                1. {t('scanCheckPage.option1')}
              </Typography>
              <Typography variant={'h2'} className={classes.options}>
                2. {t('scanCheckPage.option2')}
              </Typography>
              <Typography variant={'h2'} className={classes.options}>
                3. {t('scanCheckPage.option3')}
              </Typography>
            </div>
            <img
              src={scanCheckImage}
              alt={'scan id image'}
              className={classes.image}
            />
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const CheckScan = CheckScanComponent;
