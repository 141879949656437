import {
  APIResponseInterface,
  MethodsType,
  ObjectWithAnyKeys,
} from '../interfaces-constants/common.interfaces';
import axios, { AxiosResponse } from 'axios';
import { baseURI } from '../interfaces-constants/constant';
import { GetErrorMessageFromResponse, GetStorageData } from './main.helper';

export const makeAxiosPromise = (
  method: MethodsType,
  url: string,
  data?: ObjectWithAnyKeys<any>,
  params?: ObjectWithAnyKeys<any>,
  headers?: ObjectWithAnyKeys<any>,
) => {
  return {
    promise<T = ObjectWithAnyKeys>(
      prop = { withAuthHeader: true },
    ): Promise<APIResponseInterface<T>> {
      return new Promise((resolve, reject) => {
        const p = {
          method,
          url: `${baseURI}${url}`,
          params,
          data,
          headers: headers || {
            Accept: 'application/json, text/plain, */*',
            'Session-token': prop.withAuthHeader
              ? GetStorageData('session_token')
              : undefined,
          },
        };

        const doRequest = () =>
          axios(p)
            .then((response: AxiosResponse<APIResponseInterface<any>>) => {
              if (response.data.code) {
                if (response.data.code === 1) {
                  // sessionInit(GetStorageData('device_token')).then(() => {
                  //   doRequest();
                  // });
                  return reject(GetErrorMessageFromResponse(response.data));
                } else if (response.data.code === 210) {
                  return resolve(response.data);
                } else {
                  const err_mess = GetErrorMessageFromResponse(response.data);
                  console.error(`error at: ${method} ${url}`, err_mess);
                  return reject(err_mess);
                }
              }
              console.log(`${method} ${url} successfully`, response.data);
              resolve(response.data);
            })
            .catch(error => {
              console.error(
                `error while try to perform: ${method} ${url}`,
                error,
              );
              const code = error?.response?.data?.code;
              if (code === 'token_not_valid') {
                reject(code);
              } else {
                console.error('error: ', error?.response?.data?.message);
                reject(error?.response?.data?.message);
              }
            });
        doRequest().catch((error: string) => {
          console.error('error on request', error);
        });
      });
    },
  };
};
