/**
 * created 29.11.2023
 */
import React, { useCallback } from 'react';
import style from './keyboard.module.css';
import Keyboard from './Keyboard';
import {
  cashAmountLength,
  keyboards,
} from '../../interfaces-constants/constant';
import { Box } from '@mui/material';

interface KeyboardAmountComponentInterface {
  value: any;
  setValue: any;
}

function KeyboardAmountComponent(props: KeyboardAmountComponentInterface) {
  const { value, setValue } = props;

  const _setValue = useCallback((val: string) => {
    let cashAmount = val;
    const splitted = cashAmount.split('.');
    if (splitted.length === 2) {
      if (splitted[1].length > 2) {
        splitted[1] = splitted[1].substring(0, splitted[1].length - 1);
      }
      cashAmount = splitted.join('.');
    }
    setValue(cashAmount);
  }, []);

  return (
    <>
      <Box className={style.keyboardPhone}>
        <Keyboard
          keysArray={keyboards.numpadWithAmountControls}
          targetValue={value}
          newTargetValue={_setValue}
          target={'amount'}
          maxLength={cashAmountLength}
        />
      </Box>
    </>
  );
}

export const KeyboardAmount = KeyboardAmountComponent;
