/**
 * created 01.12.2023
 */
import React, { useCallback, useEffect, useState } from 'react';
import { usePageStyles } from '../styles/styles.page';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { t } from 'i18next';
import { FormInput } from '../components/FormInput/FormInput';
import { withStyles } from '@mui/styles';
import { colors } from '../interfaces-constants/colors';
import { createCustomer } from '../api/general.api';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { isEmpty } from 'lodash';

type FormFieldType =
  | 'firstName'
  | 'lastName'
  | 'address'
  | 'city'
  | 'state'
  | 'zip';

type DetailsFormType = Record<FormFieldType, string>;

function formInit(): DetailsFormType {
  return {
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  };
}

const fieldsOrder: Array<FormFieldType> = [
  'firstName',
  'lastName',
  'address',
  'city',
  'state',
  'zip',
];

function EnterDetailsComponent({ classes }: { classes: any }) {
  const pageClasses = usePageStyles({ withoutSpace: true });
  const {
    actions: {
      SetBottomButtons,
      SetOverlay,
      SetBigKeyboard,
      SetMainState,
      CloseSession,
    },
    state: {
      bigKeyboard: { tempValue },
      error,
    },
  } = useMainProvider();
  const [currentField, SetCurrentField] = useState<number>(0);
  const [form, SetForm] = useState<DetailsFormType>(formInit());
  const [errors, SetErrors] = useState<Partial<DetailsFormType>>({
    firstName: '',
  });
  const [allowError, SetAllowError] = useState<Record<FormFieldType, boolean>>({
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    zip: false,
    state: false,
  });

  const onNext = useCallback(() => {
    console.info('on next field action');
    if (currentField < fieldsOrder.length - 1) {
      SetCurrentField(currentField + 1);
    } else {
      SetCurrentField(0);
    }
  }, [errors, form, currentField]);

  const onConfirm = useCallback(() => {
    if (validateForm()) {
      doSendForm().then();
    }
  }, [form, currentField]);

  function validateForm(): boolean {
    let isValid = true;
    SetErrors({});
    for (const key in form) {
      // @ts-expect-error it is always form keys in the keys
      if (!form[key]) {
        isValid = false;
        SetErrors(prev => ({ ...prev, [key]: `${key} is required` }));
      }
    }
    return isValid;
  }

  async function doSendForm() {
    try {
      SetOverlay({ isOpen: true, title: t('common.processing') });
      SetMainState({ error: '' });
      await createCustomer({
        city: form.city,
        zip_code: form.zip,
        first_name: form.firstName,
        last_name: form.lastName,
        address: form.address,
        state: form.state,
      });
      await CloseSession();
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  useEffect(() => {
    validateForm();
    SetForm(prev => {
      return { ...prev, [fieldsOrder[currentField]]: tempValue };
    });
  }, [tempValue]);

  const onFieldBlur = useCallback(
    (name: FormFieldType) => {
      SetAllowError(prev => ({ ...prev, [name]: true }));
    },
    [form],
  );

  const onFieldFocus = useCallback(
    (name: FormFieldType) => {
      if (name == 'zip') {
        SetBigKeyboard({
          tempValue: form[name] || '',
          currentField: name,
        });
      } else {
        SetBigKeyboard({
          tempValue: form[name] || '',
          currentField: name,
        });
      }
      SetCurrentField(
        fieldsOrder.findIndex((element: string) => element === name),
      );
    },
    [form, currentField],
  );

  useEffect(() => {
    SetBigKeyboard({
      isOpen: !error,
    });
  }, [error]);

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        first: { enable: false },
        second: {
          enable: true,
          onAction: onNext,
          text: 'Try again',
        },
        third: {
          enable: true,
          text: t('footer.exit'),
          type: 'outlined',
          onAction: () => {
            CloseSession().then();
          },
        },
      });
    } else {
      SetBottomButtons({
        second: {
          enable: true,
          onAction: onConfirm,
          text: t('footer.confirm'),
          type: !isEmpty(errors) ? 'contained' : 'bright',
        },
        third: {
          enable: true,
          onAction: onNext,
          leftIcon: 'enterIcon',
          text: t('footer.enter'),
          type: 'outlined',
        },
      });
    }
  }, [onNext, onConfirm, error, tempValue]);

  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={pageClasses.container}>
          <div className={classes.formBlock}>
            <FormInput
              value={form.firstName}
              onBlur={onFieldBlur}
              focused={currentField === 0}
              name={'firstName'}
              onFocus={onFieldFocus}
              placeholder={'First name'}
              error={allowError.firstName ? errors.firstName : ''}
            />
            <FormInput
              value={form.lastName}
              onBlur={onFieldBlur}
              name={'lastName'}
              focused={currentField === 1}
              onFocus={onFieldFocus}
              placeholder={'Last name'}
              error={allowError.lastName ? errors.lastName : ''}
            />
            <FormInput
              value={form.address}
              onBlur={onFieldBlur}
              focused={currentField === 2}
              name={'address'}
              onFocus={onFieldFocus}
              placeholder={'Address'}
              error={allowError.address ? errors.address : ''}
            />
            <FormInput
              value={form.city}
              onBlur={onFieldBlur}
              focused={currentField === 3}
              name={'city'}
              onFocus={onFieldFocus}
              placeholder={'City'}
              error={allowError.city ? errors.city : ''}
            />
            <div className={classes.row}>
              <FormInput
                value={form.state}
                onBlur={onFieldBlur}
                focused={currentField === 4}
                name={'state'}
                onFocus={onFieldFocus}
                placeholder={'State'}
                size={'half'}
                error={allowError.state ? errors.state : ''}
              />
              <FormInput
                value={form.zip}
                onBlur={onFieldBlur}
                focused={currentField === 5}
                name={'zip'}
                onFocus={onFieldFocus}
                placeholder={'Zip'}
                size={'half'}
                error={allowError.zip ? errors.zip : ''}
              />
            </div>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const EnterDetails = withStyles(() => ({
  formBlock: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: 12,
    display: 'flex',
    gap: 60,
    borderRadius: 16,
    backgroundColor: colors.infoDarkBackground,
    padding: 40,
  },
  row: {
    display: 'flex',
    gap: 26,
    flexDirection: 'row',
  },
}))(EnterDetailsComponent);
