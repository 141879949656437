import React from 'react';
import { indexOf, isArray } from 'lodash';
import styles from './keyboard.module.css';
import { KIcon } from '../KIcon';
import { IconType } from './Keyboard';

type KeyboardKeyProps = {
  target?: any;
  value?: string;
  sendKeyValue?: any;
  availableArray?: any;
  iconsType?: IconType;
};

const KeyboardKey = ({
  value,
  sendKeyValue,
  availableArray,
  iconsType = 'big',
}: KeyboardKeyProps) => {
  const handleKeyClick = () => {
    sendKeyValue(value);
  };

  const isButtonDisabled = () => {
    if (!isArray(availableArray)) return false;
    return indexOf(availableArray, value) === -1;
  };

  switch (value) {
    case 'Delete':
      return (
        <button
          onClick={handleKeyClick}
          className={`${styles.keyboardButton} ${styles.keyboardButtonDelete}`}
          value={value}
        >
          {iconsType === 'big' ? (
            <KIcon icon={'deleteIcon'} plenty />
          ) : (
            <KIcon icon={'deleteIconSmall'} size={'xl'}/>
          )}
        </button>
      );
    case 'Clear':
      return (
        <button
          onClick={handleKeyClick}
          className={`${styles.keyboardButton} keyboard-button--clear`}
          value={value}
        >
          C
        </button>
      );
    case 'Uppercase':
      return (
        <button
          onClick={handleKeyClick}
          className={`${styles.keyboardButton} keyboard-button--uppercase`}
          value={value}
        >
          <KIcon icon={'shiftIcon'} />
        </button>
      );
    case 'Lowercase':
      return (
        <button
          onClick={handleKeyClick}
          className={`${styles.keyboardButton} keyboard-button--lowercase`}
          value={value}
        >
          <KIcon icon={'shiftIcon'} />
        </button>
      );
    default:
      return (
        <button
          onClick={handleKeyClick}
          className={`${styles.keyboardButton}`}
          disabled={isButtonDisabled()}
          value={value}
        >
          {value}
        </button>
      );
  }
};
export default KeyboardKey;
