import {makeAxiosPromise} from "../helpers/api.helper";

/**
 * ID scan request (uses for save local scanned ID at server)
 */

export const scanIDRequest = () =>
  makeAxiosPromise('post', '/kiosk/device/id-scan/').promise<null>();

/**
 * Check scan block (saves local scanned Check at server)
 */
export type CheckScanResponse = {
  check_type: string;
  cash_amount: number;
  fee_amount: number;
  currency: string;
  check_date: string;
};
export const scanCheckRequest = () =>
  makeAxiosPromise(
    'post',
    '/kiosk/device/check-scan/',
  ).promise<CheckScanResponse>();

/**
 * Enable scanner block
 */
type EnableScannerParams = {
  check_type: 'check' | 'id';
};
export const enableScannerRequest = (body: EnableScannerParams) =>
  makeAxiosPromise(
    'post',
    '/kiosk/device/enable-check-scanner/',
    body,
  ).promise<null>();

/**
 * Disable scanner block
 */
export const disableScannerRequest = () =>
  makeAxiosPromise(
    'post',
    '/kiosk/device/disable-check-scanner/',
  ).promise<null>();

/**
 * Local scan block
 */
type LocalScanResponse = {
  local_front: string;
  local_back: string;
};
export const localScanRequest = () =>
  makeAxiosPromise(
    'get',
    '/kiosk/device/local-check-scan/',
  ).promise<LocalScanResponse>();

/**
 * Send dispensable amount
 */
type DispensableAmountParams = {
  check_amount: number;
};
export const sendDispensableAmount = (body: DispensableAmountParams) =>
  makeAxiosPromise('post', '/kiosk/check/dispensable-amount/', body).promise<null>();
