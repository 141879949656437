/**
 * created 30.11.2023
 */
import React, { useCallback, useEffect } from 'react';
import { usePageStyles } from '../styles/styles.page';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { path } from '../interfaces-constants/constant';
import { useParams } from 'react-router-dom';
import { ScanDocsType } from '../interfaces-constants/common.interfaces';
import {
  CheckScanResponse,
  disableScannerRequest,
  scanCheckRequest,
  scanIDRequest,
} from '../api/device.api';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { SetStorageData } from '../helpers/main.helper';

function ScanConfirmComponent() {
  const classes = usePageStyles();
  const {
    actions: {
      SetBottomButtons,
      Navigate,
      SetOverlay,
      SetMainState,
      CloseSession,
    },
    state: { error, scannedImages },
  } = useMainProvider();
  const params = useParams<{ type: ScanDocsType }>();

  const onNext = useCallback(() => {
    if (params.type === 'check') {
      confirmScan()
        .then(response => {
          SetMainState({ checkDetails: response as CheckScanResponse });
          Navigate(path.checkDetails());
        })
        .catch();
    } else if (params.type === 'idDocument') {
      confirmScan()
        .then(() => {
          Navigate(path.scanStatus('success'));
        })
        .catch(() => {
          Navigate(path.scanStatus('unsuccessful'));
        });
    }
  }, [params?.type]);

  const onTryAgain = useCallback(() => {
    SetMainState({ error: '' });
    SetOverlay({ isOpen: true, title: t('common.processing') });
    disableScannerRequest()
      .then(() => {
        if (params.type === 'check') {
          Navigate(path.checkScan());
        } else if (params.type === 'idDocument') {
          Navigate(path.prepareId());
        }
      })
      .finally(() => {
        SetOverlay({ isOpen: false });
      });
  }, []);

  useEffect(() => {
    if (error) {
      disableScannerRequest().then();
      SetBottomButtons({
        first: { enable: false },
        second: { enable: false },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        second: { enable: true, onAction: onTryAgain, text: t('footer.try') },
        third: {
          enable: true,
          onAction: onNext,
          text: t('footer.continue'),
          type: 'bright',
        },
      });
    }
  }, [onNext, onTryAgain, error]);

  async function confirmScan() {
    try {
      SetMainState({ error: '' });
      SetOverlay({ isOpen: true });
      let response;
      if (params.type === 'idDocument') {
        response = await scanIDRequest();
        SetStorageData('is_customer_verified', true);
      } else {
        response = await scanCheckRequest();
      }
      return response.data;
    } catch (error) {
      SetMainState({ error: error as string });
      return Promise.reject(error);
    } finally {
      await disableScannerRequest();
      SetOverlay({ isOpen: false });
    }
  }

  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <div />
          <div className={classes.optionsBlock}>
            <Typography
              variant={'h2'}
              className={classes.options}
              align={'center'}
            >
              {params.type === 'idDocument'
                ? t('scanConfirmPage.description')
                : t('scanConfirmPage.checkDescription')}
            </Typography>
          </div>
          <div className={classes.previewContainer}>
            <div className={classes.scanPart}>
              <Typography variant={'h3'}>
                {t('scanConfirmPage.front')}
              </Typography>
              <img
                src={scannedImages.local_front}
                alt={'front image'}
                className={classes.previewImage}
              />
            </div>
            <div className={classes.scanPart}>
              <Typography variant={'h3'}>
                {t('scanConfirmPage.back')}
              </Typography>
              <img
                src={scannedImages.local_back}
                alt={'back image'}
                className={classes.previewImage}
              />
            </div>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const ScanConfirm = ScanConfirmComponent;
