/**
 * created 29.11.2023
 */
import React, { useMemo } from 'react';
import style from '../CodeInput/CodeInput.module.css';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { MyTheme } from '../../styles/my.theme';

interface AmountInputComponentInterface {
  value: string;
  setValue: any;
  errorMsg?: string;
  isValid: boolean;
}

function AmountInputComponent(props: AmountInputComponentInterface) {
  const { value, setValue, isValid, errorMsg } = props;
  const classes = useStyles({ isValid, errorMsg });

  const formattedValue = useMemo(() => {
    const _val = Number(value);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(_val);
  }, [value]);

  return (
    <div className={classes.container}>
      <div className={classes.searchBox}>
        <input
          className={style.phoneInput}
          value={formattedValue}
          onChange={e => setValue(e.target.value)}
        />
      </div>
      <Typography
        variant={'h4'}
        className={classes.errorMsg}
        color={MyTheme.palette.error.main}
      >
        {errorMsg}
      </Typography>
    </div>
  );
}

export const AmountInput = AmountInputComponent;

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    width: '100%',
    maxWidth: '760px',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.05)',
    outline: 'none',
    border: '4px #667080 solid',
    borderColor: (props: any) =>
      props.isValid
        ? theme.palette.bordersPalette.light
        : theme.palette.bordersPalette.error,
    borderRadius: '24px',
    fontSize: '32px',
    margin: '0 auto',
    color: '#ffffff',
    '& > img:nth-of-type(1)': {
      marginRight: '45px',
      cursor: 'pointer',
    },
  },
  container: {
    flexDirection: 'column',
    width: '100%',
    maxWidth: '760px',
    gap: theme.palette.spaces.min,
  },
  errorMsg: {
    visibility: (props: any) => (props.errorMsg ? 'visible' : 'hidden'),
    height: 24,
    color: theme.palette.error.main,
    paddingTop: theme.palette.spaces.min,
  },
}));
