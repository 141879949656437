import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { colors } from '../interfaces-constants/colors';
import { GlobalProps } from '../interfaces-constants/common.interfaces';

export const usePageStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    marginTop: (props?: Partial<GlobalProps>) =>
      !props?.withoutSpace ? 53 : 0,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.palette.spaces.main,
    width: theme.palette.spaces.mainWidth,
    textAlign: 'center',
  },
  infoContainer: {
    display: 'flex',
    gap: 38,
    width: '100%',
    flexDirection: 'column',
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: theme.palette.spaces.workspaceWidth,
    height: 270,
    gap: theme.palette.spaces.middle,
  },
  phoneKeyboardContainer: {
    width: theme.palette.spaces.workspaceWidth,
    marginBottom: theme.palette.spaces.main,
  },
  emptyDescription: {
    height: 0,
  },
  optionsBlock: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    textAlign: 'left',
    borderRadius: 16,
    backgroundColor: colors.infoDarkBackground,
    padding: 40,
  },
  previewContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.palette.spaces.middle,
  },
  scanPart: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.palette.spaces.middle,
  },
  previewImage: {
    width: 450,
    borderRadius: 24,
  },
  options: {
    fontWeight: '400 !important',
  },
  details: {
    fontWeight: '400 !important',
    color: `${colors.primaryLight} !important`,
  },
  image: {
    borderRadius: 24,
  },
}));
