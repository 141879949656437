/**
 * created 27.11.2023
 */
import React, { useCallback } from 'react';
import { SizesType } from '../interfaces-constants/common.interfaces';
import { makeStyles } from '@mui/styles';
import * as icons from '../asset/svg';
import { IconNameType } from '../asset/svg';

interface CIconComponentInterface {
  size?: SizesType;
  color?: string;
  icon: IconNameType;
  width?: number;
  height?: number;
  spaceRight?: number;
  plenty?: boolean;
  onPress?: (name: IconNameType) => void;
}

function CIconComponent(props: CIconComponentInterface) {
  const {
    icon,
    size = 'md',
    color,
    width,
    height,
    spaceRight = 0,
    plenty = false,
    onPress,
  } = props;
  const classes = useStyles({ color, size, height, width, spaceRight });

  const Icon = icon ? icons[icon] : null;
  if (!Icon) return null;

  const _onPress = useCallback(() => {
    if (typeof onPress === 'function') {
      onPress(icon);
    }
  }, [onPress, icon]);

  return (
    <>
      {plenty ? (
        <Icon />
      ) : (
        <span className={classes.icon} onClick={_onPress}>
          <Icon />
        </span>
      )}
    </>
  );
}

export const KIcon = CIconComponent;

function getIconSizeStyle(size = 'md'): { width: number; height: number } {
  switch (size) {
    case 'xs':
      return { width: 16, height: 16 };
    case 'sm':
      return { width: 20, height: 20 };
    case 'md':
      return { width: 24, height: 24 };
    case 'lg':
      return { width: 28, height: 28 };
    case 'xl':
      return { width: 34, height: 34 };
    case 'sxl':
      return { width: 56, height: 56 };
    default:
      return { width: 16, height: 16 };
  }
}

const useStyles = makeStyles({
  icon: {
    width: (props: Partial<CIconComponentInterface>) =>
      props.width ? `${props?.width} !important` : `auto`,
    height: (props: Partial<CIconComponentInterface>) =>
      props.height ? `${props?.height} !important` : `auto`,
    verticalAlign: 'middle',
    color: (props: Partial<CIconComponentInterface>) =>
      props.color ? props.color : '',
    display: 'inline-block',
    '& svg path': {
      fill: (props: Partial<CIconComponentInterface>) =>
        props.color ? props.color : '',
    },
    marginRight: (props: Partial<CIconComponentInterface>) => props.spaceRight,
    '& svg': {
      verticalAlign: 'middle',
      width: (props: Partial<CIconComponentInterface>) =>
        props.width || getIconSizeStyle(props.size).width,
      height: (props: Partial<CIconComponentInterface>) =>
        props.height || getIconSizeStyle(props.size).height,
    },
  },
  hidden: {
    display: 'none',
  },
});
