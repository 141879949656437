/**
 * created 30.11.2023
 */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { Spinner } from '../Spinner/Spinner';

interface LoaderOverlayComponentInterface {
  title: string;
  isOpen: boolean;
  subTitle: string;
}

function LoaderOverlayComponent(props: LoaderOverlayComponentInterface) {
  const { title, isOpen, subTitle } = props;

  const classes = useStyles({ isOpen });

  return (
    <>
      <div className={classes.container}>
        <div className={classes.topPart} />
        <div className={classes.bottomPart}>
          <div className={classes.visualContainer}>
            <Typography variant={'h2'} className={classes.variantCorrection}>
              {title}
            </Typography>
            <Spinner variant={'default'} size={'lg'} />
            <Typography variant={'h4'} className={classes.variantCorrection}>
              {subTitle}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export const LoaderOverlay = LoaderOverlayComponent;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: (props: Partial<LoaderOverlayComponentInterface>) =>
      props.isOpen ? 'flex' : 'none',
    position: 'absolute',
    height: '100vh',
    width: '100%',
    flexDirection: 'column',
    zIndex: 10,
  },
  topPart: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    opacity: 0.8,
    flex: 1,
  },
  bottomPart: {
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    borderTop: '3px solid',
    borderTopColor: theme.palette.bordersPalette.light,
    alignItems: 'center',
    justifyContent: 'center',
  },
  visualContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.palette.spaces.main,
    width: theme.palette.spaces.workspaceWidth,
    alignItems: 'center',
    justifyContent: 'center',
  },
  variantCorrection: {
    color: `${theme.palette.text.primary} !important`,
    fontWeight: '400 !important',
  },
}));
