/**
 * created 30.11.2023
 * see more spinners at https://cssloaders.github.io/
 */
import React, { useMemo } from 'react';
import classes from './spinner.module.css';
import classes1 from './spinner1.module.css';
import classes2 from './spinner2.module.css';
import classes3 from './spinner3.module.css';
import {
  SizesType,
  SpinnerVariantsType,
} from '../../interfaces-constants/common.interfaces';
import { makeStyles } from '@mui/styles';

interface SpinnerComponentInterface {
  variant: SpinnerVariantsType;
  size?: SizesType;
  scale?: string;
}

function SpinnerComponent(props: SpinnerComponentInterface) {
  const { variant, size = 'md' } = props;

  const scale = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'scale(0.5)';
      case 'sm':
        return 'scale(1)';
      case 'lg':
        return 'scale(2)';
      case 'xl':
        return 'scale(2.5)';
      default:
        return 'scale(1.5)';
    }
  }, [size]);

  const classesVar = useMemo(() => {
    switch (variant) {
      case 'v1':
        return classes1;
      case 'v2':
        return classes2;
      case 'v3':
        return classes3;
      default:
        return classes;
    }
  }, [variant]);

  const dynClasses = useStyles({ scale });

  return (
    <div className={dynClasses.spinnerContainer}>
      <span className={classesVar.loader} />
    </div>
  );
}

export const Spinner = SpinnerComponent;

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    textAlign: 'center',
    padding: 40,
    background: 'none',
    color: '#FFFFFF',
    transform: (props: Partial<SpinnerComponentInterface>) => props.scale,
  },
}));
