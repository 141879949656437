/**
 * created 29.11.2023
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePageStyles } from '../styles/styles.page';
import { Typography } from '@mui/material';
import KeyBoardPhone from '../components/Keyboard/KeyBoardPhone';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { authCodeLength, path } from '../interfaces-constants/constant';
import { t } from 'i18next';
import CodeInput from '../components/CodeInput/CodeInput';
import {
  customerAuthorize,
  customerVerify,
  getCustomerStatus,
} from '../api/general.api';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import {
  GetErrorMessageFromResponse,
  SetStorageData,
} from '../helpers/main.helper';

function AuthCodeComponent() {
  const {
    state: { authCode, validateAllow, error, phoneNumber },
    actions: {
      SetAuthCode,
      SetBottomButtons,
      Navigate,
      SetMainState,
      SetOverlay,
      CloseSession,
    },
  } = useMainProvider();
  const [errMsg, SetErrMsg] = useState('');

  const isValid = useMemo(
    () => (validateAllow ? authCode.length === authCodeLength : true),
    [authCode, validateAllow],
  );

  function _setAuthCode(value: string) {
    SetErrMsg('');
    SetAuthCode(value);
  }

  const onNext = useCallback(() => {
    SetMainState({ validateAllow: true });
    if (authCode.length === authCodeLength) {
      doVerify();
    }
  }, [authCode]);

  async function tryAgain() {
    try {
      SetOverlay({ isOpen: true, title: t('common.processing') });
      await customerAuthorize({ phone_number: phoneNumber });
    } catch (err) {
      SetMainState({ error: err as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  function doVerify() {
    SetOverlay({ isOpen: true, title: 'Processing...' });
    SetMainState({ error: '' });
    customerVerify({ auth_code: authCode })
      .then(response => {
        if (response.code) {
          throw new Error(GetErrorMessageFromResponse(response));
        }
        getCustomerStatus()
          .then(response => {
            SetStorageData('is_customer_verified', response.data.is_verified);
            if (response.data.is_verified) {
              Navigate(path.cashAmount());
            } else {
              Navigate(path.prepareId());
            }
          })
          .catch(error => {
            SetMainState({ error });
          })
          .finally(() => {
            SetOverlay({ isOpen: false });
          });
      })
      .catch(error => {
        if (error) {
          SetErrMsg(error as string);
        } else {
          SetErrMsg('wrong auth code');
        }
      })
      .finally(() => {
        SetOverlay({ isOpen: false });
      });
  }

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        first: { enable: false },
        second: { enable: false },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        first: {
          enable: true,
          onAction: () => {
            SetMainState({ error: '' });
            Navigate(path.home());
          },
        },
        second: {
          enable: true,
          onAction: () => {
            tryAgain().then();
          },
        },
        third: {
          enable: !error,
          onAction: onNext,
          type: authCode.length === 5 ? 'bright' : 'contained',
        },
      });
    }
  }, [onNext, authCode, error]);

  const classes = usePageStyles({ withoutSpace: true });
  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={JSON.stringify(error)}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <Typography variant={'h2'}>{t('authCodePage.title')}</Typography>
          <div className={classes.phoneContainer}>
            <CodeInput
              value={authCode}
              setValue={_setAuthCode}
              isValid={isValid}
              errorMsg={errMsg}
            />
            <Typography variant={'h4'}>
              {t('authCodePage.description')}
            </Typography>
          </div>
          <div className={classes.phoneKeyboardContainer}>
            <KeyBoardPhone value={authCode} setValue={_setAuthCode} length />
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const AuthCode = AuthCodeComponent;
