/**
 * created 24.11.2023
 */
import React, {useCallback} from 'react';
import {Box} from '@mui/material';
import {ButtonSquare} from '../ButtonSquare';
import {useMainProvider} from "../../context/MainProvider/MainProvider";

// interface HeaderComponentInterface {
// }

function HeaderComponent() {
  const {
    actions: { CloseSession },
  } = useMainProvider();
  const onHomePress = useCallback(() => {
    CloseSession().then();
  }, []);

  return (
    <Box>
      <Box sx={styles.headerBox}>
        <Box>
          <ButtonSquare
            iconName={'homeIcon'}
            name={'home'}
            onPress={onHomePress}
          />
        </Box>
      </Box>
    </Box>
  );
}

export const Header = HeaderComponent;

const styles = {
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',
  },
};
