/**
 * created 29.11.2023
 */
import React, { useCallback, useEffect } from 'react';
import { usePageStyles } from '../styles/styles.page';
import { t } from 'i18next';
import { Typography } from '@mui/material';
import scanIdImage from '../asset/images/scan_id_image.png';
import { path } from '../interfaces-constants/constant';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { enableScannerRequest, localScanRequest } from '../api/device.api';
import { GetErrorMessageFromResponse } from '../helpers/main.helper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';

function PrepareIDComponent() {
  const {
    state: { error },
    actions: {
      SetBottomButtons,
      Navigate,
      SetMainState,
      SetOverlay,
      StoreScannedImages,
      ClearLocalImages,
      CloseSession,
    },
  } = useMainProvider();
  const classes = usePageStyles();

  useEffect(() => {
    enableScannerRequest({ check_type: 'id' })
      .then(response => {
        if (response.code) {
          throw new Error(GetErrorMessageFromResponse(response));
        }
      })
      .catch(error => {
        SetMainState({ error: error as string });
      });
  }, []);

  const onNext = useCallback(() => {
    doScanID().then();
  }, []);

  async function doScanID() {
    try {
      SetOverlay({ isOpen: true });
      SetMainState({ error: '' });
      ClearLocalImages();
      const response = await localScanRequest();
      StoreScannedImages(response.data);
      Navigate(path.scanConfirm('idDocument'));
    } catch (error) {
      SetMainState({ error: error as string });
    } finally {
      SetOverlay({ isOpen: false });
    }
  }

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        first: { enable: false },
        second: {
          enable: true,
          onAction: () => {
            doScanID();
          },
          text: 'Try again',
        },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        first: { enable: false, onAction: () => Navigate(path.home()) },
        third: {
          enable: true,
          onAction: onNext,
          text: "I'm ready",
          type: 'bright',
        },
      });
    }
  }, [onNext, error]);

  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={error}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <Typography variant={'h1'}>{t('prepareIDPage.title')}</Typography>
            <div className={classes.optionsBlock}>
              <Typography variant={'h2'} className={classes.options}>
                1. {t('prepareIDPage.option1')}
              </Typography>
              <Typography variant={'h2'} className={classes.options}>
                2. {t('prepareIDPage.option2')}
              </Typography>
              <Typography variant={'h2'} className={classes.options}>
                3. {t('prepareIDPage.option3')}
              </Typography>
            </div>
            <div>
              <img src={scanIdImage} alt={'scan id image'} />
            </div>
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const PrepareID = PrepareIDComponent;
