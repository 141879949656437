/**
 * created 01.12.2023
 */
import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface FormInputComponentInterface {
  size?: 'full' | 'half';
  value: string;
  onChange?: (value: string, name: any) => void;
  placeholder?: string;
  onBlur?: (name: any) => void;
  onFocus?: (name: any) => void;
  error?: string;
  focused?: boolean;
  name?: string;
}

function FormInputComponent({
  size = 'full',
  value,
  onChange,
  placeholder = '',
  onBlur,
  onFocus,
  name = '',
  error = '',
  focused = false,
}: FormInputComponentInterface) {
  const classes = useStyles({ size, error, focused });
  const reference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (reference.current && focused) {
      reference.current?.focus();
    }
  }, [focused]);

  const _onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof onChange === 'function') {
        onChange(event.target.value, name);
      }
    },
    [onChange],
  );

  const _onBlur = useCallback(() => {
    if (typeof onBlur === 'function') {
      onBlur(name);
    }
  }, [name, onBlur]);

  const _onFocus = useCallback(() => {
      console.info('focused: ', name);
    if (typeof onFocus === 'function') {
      onFocus(name);
    }
  }, [name, onFocus]);

  return (
    <>
      <div className={classes.container}>
        <input
          className={classes.input}
          value={value}
          ref={reference}
          onChange={_onChange}
          placeholder={placeholder}
          onBlur={_onBlur}
          onFocus={_onFocus}
        />
      </div>
    </>
  );
}

export const FormInput = FormInputComponent;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px 40px',
    backgroundColor: theme.palette.background.default,
    borderWidth: 3,
    borderColor: (props: Partial<FormInputComponentInterface>) =>
      props.error
        ? theme.palette.bordersPalette.error
        : props.focused
          ? theme.palette.bordersPalette.peach
          : theme.palette.bordersPalette.light,
    borderStyle: 'solid',
    borderRadius: 16,
    display: 'flex',
    width: (props: Partial<FormInputComponentInterface>) =>
      props.size === 'full' ? 800 : 345,
  },
  input: {
    fontFamily: 'Roboto',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '36px',
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  },
}));
