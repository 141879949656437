/**
 * created 27.11.2023
 */
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { KIcon } from './KIcon';
import { IconNameType } from '../asset/svg';
import { Theme } from '@mui/material';

interface ButtonSquareComponentInterface {
  onPress?: (name: string) => void;
  name: string;
  iconName: IconNameType;
  color?: string;
}

function ButtonSquareComponent(props: ButtonSquareComponentInterface) {
  const { onPress, name, iconName, color } = props;

  const classes = useStyles({ color });

  const _onClick = useCallback(() => {
    if (typeof onPress === 'function') {
      onPress(name);
    }
  }, [name]);

  return (
    <>
      <div onClick={_onClick} className={classes.container}>
        <KIcon icon={iconName} />
      </div>
    </>
  );
}

export const ButtonSquare = ButtonSquareComponent;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    borderRadius: 16,
    backgroundColor: theme.palette.background.lighter,
    borderWidth: 3,
    borderStyle: 'solid',
    borderColor: (props: Partial<ButtonSquareComponentInterface>) =>
      props.color || theme.palette.secondary.main,
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: 72,
    cursor: 'pointer',
  },
}));
