/**
 * created 24.11.2023
 */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Layout/Layout';
import { Home } from '../pages/Home';
import { path } from '../interfaces-constants/constant';
import { AuthCode } from '../pages/AuthCode';
import { CashAmount } from '../pages/CashAmount';
import { PrepareID } from '../pages/PrepareID';
import { ScanConfirm } from '../pages/ScanConfirm';
import { ScanStatus } from '../pages/ScanStatus';
import { EnterDetails } from '../pages/EnterDetails';
import { CheckScan } from '../pages/CheckScan';
import { CheckDetails } from '../pages/CheckDetails';
import { UnderReview } from '../pages/UnderReview';
import { AvailableChecks } from '../pages/AvailableChecks'; // interface AppRoutesComponentInterface {

// interface AppRoutesComponentInterface {
// }

function AppRoutesComponent() {
  return (
    <Routes>
      <Route path={path.home()} element={<Layout />}>
        <Route index element={<Home />}></Route>
        <Route path={path.authCode()} element={<AuthCode />}></Route>
        <Route path={path.cashAmount()} element={<CashAmount />}></Route>
        <Route path={path.prepareId()} element={<PrepareID />}></Route>
        <Route path={path.scanConfirm()} element={<ScanConfirm />}></Route>
        <Route path={path.scanStatus()} element={<ScanStatus />}></Route>
        <Route path={path.enterDetails()} element={<EnterDetails />}></Route>
        <Route path={path.checkScan()} element={<CheckScan />}></Route>
        <Route path={path.checkDetails()} element={<CheckDetails />}></Route>
        <Route path={path.checkUnderReview()} element={<UnderReview />}></Route>
        <Route
          path={path.availableChecks()}
          element={<AvailableChecks />}
        ></Route>
      </Route>
    </Routes>
  );
}

export const AppRoutes = AppRoutesComponent;
