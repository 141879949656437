/**
 * created 29.11.2023
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMainProvider } from '../context/MainProvider/MainProvider';
import { path } from '../interfaces-constants/constant';
import { usePageStyles } from '../styles/styles.page';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { AmountInput } from '../components/AmountInput/AmountInput';
import { KeyboardAmount } from '../components/Keyboard/KeyboardAmount';
import { sendDispensableAmount } from '../api/device.api';
import { ViewWrapper } from '../components/ViewWrapper/ViewWrapper';
import { ViewStatus } from '../components/ViewStatus/ViewStatus';

function CashAmountComponent() {
  const {
    state: { cashAmount, validateAllow, error },
    actions: {
      SetCashAmount,
      SetBottomButtons,
      Navigate,
      SetMainState,
      SetOverlay,
      CloseSession,
    },
  } = useMainProvider();

  const isValid = useMemo(
    () => (validateAllow ? Number(cashAmount) > 0 : true),
    [cashAmount, validateAllow],
  );
  const [errMsg, SetErrMsg] = useState('');

  const _setAmount = (value: string) => {
    SetErrMsg('');
    SetCashAmount(value);
  };

  const onNext = useCallback(() => {
    SetMainState({ validateAllow: true, error: '' });
    if (Number(cashAmount) > 0) {
      SetOverlay({ isOpen: true });
      sendDispensableAmount({ check_amount: Number(cashAmount) })
        .then(() => {
          SetCashAmount('0');
          Navigate(path.checkScan());
        })
        .catch(error => {
          if (error) {
            SetErrMsg(error as string);
          } else {
            SetErrMsg('wrong check amount');
          }
          // if (error.search('#20') !== -1) {
          //   SetErrMsg('wrong check amount');
          // } else {
          //   SetErrMsg('');
          //   SetMainState({ error });
          // }
        })
        .finally(() => {
          SetOverlay({ isOpen: false });
        });
    }
  }, [cashAmount]);

  useEffect(() => {
    if (error) {
      SetBottomButtons({
        first: { enable: false },
        third: {
          enable: true,
          text: t('footer.exit'),
          onAction: () => {
            CloseSession().then();
          },
          type: 'bright',
        },
      });
    } else {
      SetBottomButtons({
        first: { enable: true, onAction: () => SetMainState({ error: '' }) },
        third: {
          enable: true,
          onAction: onNext,
          type: Number(cashAmount) > 0 ? 'bright' : 'contained',
        },
      });
    }
  }, [onNext, cashAmount, error]);

  const classes = usePageStyles();
  return (
    <>
      <ViewWrapper enable={!!error}>
        <ViewStatus
          statusName={'unsuccessful'}
          title={'server error'}
          subtitle={JSON.stringify(error)}
        />
      </ViewWrapper>
      <ViewWrapper enable={!error}>
        <div className={classes.container}>
          <Typography variant={'h2'}>{t('cashAmountPage.title')}</Typography>
          <div className={classes.phoneContainer} style={{ marginTop: -53 }}>
            <AmountInput
              value={cashAmount}
              setValue={_setAmount}
              isValid={isValid}
              errorMsg={errMsg}
            />
            <div className={classes.emptyDescription} />
          </div>
          <div className={classes.phoneKeyboardContainer}>
            <KeyboardAmount value={cashAmount} setValue={_setAmount} />
          </div>
        </div>
      </ViewWrapper>
    </>
  );
}

export const CashAmount = CashAmountComponent;
