/**
 * created 05.12.2023
 */
import React, { useCallback } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';
import { colors } from '../interfaces-constants/colors';
import { usePageStyles } from '../styles/styles.page';

interface CheckItemComponentInterface {
  type: 'active' | 'inactive';
  date: string;
  amount: string;
  onPress?: (id: string) => void;
}

function CheckItemComponent(props: CheckItemComponentInterface) {
  const { type, date, amount, onPress } = props;
  const classes = usePageStyles();
  const localClasses = useLocalClasses({ type });

  const _onPress = useCallback(() => {
    if (typeof onPress === 'function' && type === 'active') {
      // todo implement id as parameter
      onPress(amount);
    }
  }, [onPress, type]);

  return (
    <div onClick={_onPress} style={{ width: '100%' }}>
      <Grid item xs={12} container className={localClasses.checkItem}>
        <Grid item xs={4}>
          <Typography
            variant={'h2'}
            align={'left'}
            className={[classes.details, localClasses.dateColor].join(' ')}
          >
            {date}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant={'h2'}
            align={'left'}
            className={localClasses.amount}
          >
            {amount}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant={'h2'}
            align={'right'}
            className={localClasses.textColor}
          >
            {type === 'active'
              ? t('availableChecksPage.withdraw')
              : t('availableChecksPage.noCash')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export const CheckItem = CheckItemComponent;

const useLocalClasses = makeStyles((theme: Theme) => ({
  checkItem: {
    borderRadius: 24,
    padding: theme.palette.spaces.middle,
    border: (props: Partial<CheckItemComponentInterface>) =>
      props.type === 'active'
        ? `4px solid ${theme.palette.bordersPalette.peach}`
        : `4px solid ${theme.palette.bordersPalette.midLight}`,
  },
  amount: {
    color: (props: Partial<CheckItemComponentInterface>) =>
      props.type === 'active'
        ? `${colors.primaryLight} !important`
        : `${colors.darkGrey} !important`,
  },
  textColor: {
    color: (props: Partial<CheckItemComponentInterface>) =>
      props.type === 'active'
        ? `${colors.primary} !important`
        : `${colors.darkGrey} !important`,
  },
  dateColor: {
    color: (props: Partial<CheckItemComponentInterface>) =>
      props.type === 'active' ? '' : `${colors.darkGrey} !important`,
  },
}));
