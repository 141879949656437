import React, { useEffect, useMemo } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { AppRoutes } from './components/AppRoutes';
import { useMainProvider } from './context/MainProvider/MainProvider';
import { MyTheme } from './styles/my.theme';
import { useSearchParams } from 'react-router-dom';

function App() {
  const [params] = useSearchParams();
  const {
    actions: { InitSession },
  } = useMainProvider();

  const params_token = useMemo(() => params.get('token'), [params]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    // console.info('params', params_token);
    // const token_from_storage = GetStorageData('device_token') || '';
    // const session_token = GetStorageData('session_token');
    if (params_token) {
      InitSession(params_token).then();
    }
    // if (!session_token) {
    //   InitSession(params_token || token_from_storage).then();
    // }
  }, [params_token]);

  return (
    <ThemeProvider theme={MyTheme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
