import { makeAxiosPromise } from '../helpers/api.helper';

export type LocationDetailsType = {
  name: string;
  timezone: string;
  address: string;
  country: string;
  kiosk_short_id: number;
  kiosk_model: string;
  currency: string;
};
export type OrganizationDetailsType = {
  name: string;
  contact_email: string;
  contact_phone_number: string;
};

/**
 * Session init request block
 */
type InitRequestParams = {
  platform_session: string;
};
type InitRequestResponse = {
  session_token: string;
  location_details: LocationDetailsType;
  organization_details: OrganizationDetailsType;
};
export const sessionInit = (body: InitRequestParams) =>
  makeAxiosPromise(
    'post',
    '/kiosk/session/init/',
    body,
  ).promise<InitRequestResponse>({ withAuthHeader: false });

/**
 * Session close request block
 */
export const closeSession = () =>
  makeAxiosPromise('post', '/kiosk/session/close/').promise<null>();

/**
 * Authorize customer request block
 */
type CustomerAuthParams = {
  phone_number: string;
};
export const customerAuthorize = (body: CustomerAuthParams) => {
  return makeAxiosPromise(
    'post',
    '/kiosk/customer/auth/send/',
    body,
  ).promise<null>();
};

/**
 * Verify customer auth code
 */
type CustomerVerifyParams = {
  auth_code: string;
};
export type CustomerVerifyResponse = {
  customer_phone: string;
  customer_email: string;
};
export const customerVerify = (body: CustomerVerifyParams) =>
  makeAxiosPromise(
    'post',
    '/kiosk/customer/auth/verify/',
    body,
  ).promise<CustomerVerifyResponse>();

/**
 * Get customer status request block
 */
type CustomerStatusResponse = {
  is_verified: boolean;
};
export const getCustomerStatus = () =>
  makeAxiosPromise(
    'get',
    '/kiosk/customer/kyc/status/',
  ).promise<CustomerStatusResponse>();

/**
 * Get available check list
 */
type CheckItem = {
  id: string;
  check_type: string;
  time_created: string;
  cash_amount: number;
};
type getAvailableChecksResponse = Array<CheckItem>;
export const getAvailableChecks = () =>
  makeAxiosPromise(
    'get',
    '/kiosk/check/dispensable/',
  ).promise<getAvailableChecksResponse>();

/**
 * Check decide request block
 */
type CheckDecideResponse = {
  is_approved: string;
};
export const checkDecide = () =>
  makeAxiosPromise(
    'post',
    '/kiosk/check/decide/',
  ).promise<CheckDecideResponse>();

/**
 * Finish check request block
 */
export const finishCheck = () =>
  makeAxiosPromise('post', '/kiosk/check/finish/').promise<null>();

/**
 * Create customer request block
 */
type CreateCustomerParams = {
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
};
export const createCustomer = (body: CreateCustomerParams) =>
  makeAxiosPromise('post', '/kiosk/customer/kyc/create/', body).promise<null>();
